// import { useRoute } from "vue-router";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ArrowRight } from "@element-plus/icons";
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "Index",
  // eslint-disable-next-line vue/require-prop-types
  props: ["allpath"],
  setup: function setup(props) {
    var a = reactive(props.allpath);
    var router = useRouter();

    function jump(val) {
      if (val.path) {
        router.push({
          name: val.path,
          query: val.query
        });
      }
    }

    function jum() {
      router.push("/");
    }

    return {
      a: a,
      ArrowRight: ArrowRight,
      jump: jump,
      jum: jum
    };
  }
});