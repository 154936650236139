import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { ref } from "vue";
import { BellFilled, Checked, UserFilled, Avatar, WalletFilled, GoodsFilled, Platform, StarFilled, Message, ArrowRightBold, List, Promotion, HelpFilled, Flag, TrendCharts, Stamp, Tools, CameraFilled } from "@element-plus/icons";
export default defineComponent({
  components: {
    BellFilled: BellFilled,
    Checked: Checked,
    UserFilled: UserFilled,
    Avatar: Avatar,
    WalletFilled: WalletFilled,
    GoodsFilled: GoodsFilled,
    Platform: Platform,
    StarFilled: StarFilled,
    Message: Message,
    ArrowRightBold: ArrowRightBold,
    List: List,
    Promotion: Promotion,
    HelpFilled: HelpFilled,
    Flag: Flag,
    Stamp: Stamp,
    TrendCharts: TrendCharts,
    Tools: Tools,
    CameraFilled: CameraFilled
  },
  props: {
    iconFlag: {
      type: String,
      required: true
    },
    iconName: {
      type: String,
      default: ""
    }
  },
  setup: function setup(props) {
    var meunList = ref(useStore().state.column.meunList); // 获取当前选择模块的name属性

    var getName = computed(function () {
      var a = meunList.value.find(function (item) {
        return item.icon == props.iconFlag;
      });
      return a.name;
    });
    return {
      meunList: meunList,
      getName: getName
    };
  }
});